@use 'mixins';

.FlashMessageHolder {
    z-index: 501;
    display: inline-block;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 450px;
    max-width: 100%;
    padding: var(--hugePadding);
    padding-top: 0;
    box-sizing: border-box;

    .FlashMessage {
        display: block;
        position: relative;
        padding: var(--largePadding);
        border-left: 3px solid rgb(var(--mainColor));
        background: white;
        margin-top: var(--largePadding);
        max-width: 100%;
        overflow: hidden;
        line-height: 1.2em;

        h1 {
            font-size: 1.15em;

            &:last-child {
                padding: var(--smallPadding) 0 0;
            }
        }

        p {
            overflow: hidden;
        }

        &.success {
            border-left-color: rgb(var(--successColor));

            :global(.Icon) {
                color: rgb(var(--successColor));
            }
        }

        &.error {
            border-left-color: rgb(var(--errorColor));

            :global(.Icon) {
                color: rgb(var(--errorColor));
            }
        }

        &.warning {
            border-left-color: rgb(var(--warningColor));

            :global(.Icon) {
                color: rgb(var(--warningColor));
            }
        }

        :global(.Icon) {
            font-size: 2em;
            float: left;
            margin: 0.2em 0.5em 0 0;

            &:global(.close-icon) {
                position: absolute;
                top: var(--standardPadding);
                right: var(--standardPadding);
                color: rgb(var(--textColor));
                margin: 0;
                font-size: 1em;
                font-weight: 700;
                cursor: pointer;
                float: right;
            }
        }
    }
}
