@use 'mixins';

main > section {
    background: rgb(var(--lightSectionBack));

    // TODO: use var for shadow color
    box-shadow: 0 10px 20px 0 rgba(#12263f, 0.03);
    margin: var(--largePadding);
    padding: var(--largePadding);

    > header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: var(--largePadding);

        button,
        .button {
            margin-left: var(--smallPadding);
        }
    }

    .self-right {
        display: block;
        width: fit-content;
        margin-right: 0;
        margin-left: auto;
    }
}

@include mixins.responsive(l, below) {
    main > section {
        margin-left: 0;
        margin-right: 0;
    }
}
