.phpdebugbar {
    display: none;
}

main {
    > h1 {
        margin: 0 var(--largePadding);
        text-transform: uppercase;
    }
}

.deprecated-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875em;
    color: rgb(var(--invertedBackground));
}

.flex {
    display: flex;
}

.justify-end {
    justify-content: end;
}

.justify-between {
    justify-content: space-between;
}

.w-full {
    width: 100%;
}

.mb-4 {
    margin-bottom: 1rem;
}
